html, body {
  height: 100%;
  width: 100%;
  background: $black;
}

body {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
.grid-container {
  position: relative;
}
.cell.centered {
  text-align: center;
}

#main-image-background {
  position: fixed;
  top:0;
  left:0;
  right:0;
  height: 100%;
  width: 100%;
  background: url(../img/main-image.jpg) center no-repeat;
  background-size: cover;
}

[data-aos="fade-out"] {
  opacity: 1 !important;
  transition-property: transform, opacity;
  transition-duration: 0s!important;

  &.aos-animate {
    opacity: 0!important;
  }
}

.main-image {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  position: relative;
  & > * {
    flex-basis: 100%;
    position: relative;
  }
  .logo {
    margin-bottom: 3rem;
    padding: 0 1rem;
    @include breakpoint(medium) {
      padding: 0 3rem;
    }
  }
  h1 {
    font-size: 1.5rem;
    @include breakpoint(medium) {
      font-size: 2.5rem;
    }
    color: $white;
    font-weight: 100;
    line-height: 1.1;
  }
  .grid-container {
    margin:0;
  }
}
.top {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  .logo {
    width: 280px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @include breakpoint(medium) {
      width: 400px;
      margin-top: 3rem;
    }
  }
}

.main-nav {
  margin-top: 2rem;
  width:100%;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 1rem;
    a {
      display: block;
      color: $blue;
      margin-bottom: 1rem;
      transition: all 0.3s ease;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        width: 100%;
        height: 2px;
        background: mix($blue,$white,20%);
        transform: scale(0);
        transition: all 0.5s ease;
      }
      &:hover,&:active,&:focus {
        color: mix($blue,$white,20%);
        &:before {
          transform: scale(1);
        }
      }
    }
  }
}

#logo {
  h1 {
    text-shadow: 1px 1px 5px rgba(0,0,0,1);
  }
}

.grid-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.content {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
  text-align: center;
  & > * {
    position: relative;
  }
  p {
    font-size: 1.125rem;
    @include breakpoint(medium) {
      font-size: 1.3125rem;
    }
    line-height: 1.4;
  }
  h2,h3,h4 {
    font-weight: bold;
    margin-bottom: 2rem;
    color: $blue;
    text-transform: uppercase;
  }
  &.left{
    &:before {
      position: absolute;
      bottom: -6rem;
      right: 50%;
      content: '';
      display: block;
      width: 50%;
      height: 6rem;
      background: inherit;
      transform: skewY(5deg);
      transform-origin: top right;
      z-index: 10;
    }
    &:after {
      position: absolute;
      bottom: -6rem;
      left: 50%;
      content: '';
      display: block;
      width: 50%;
      height: 6rem;
      background: inherit;
      transform: skewY(-5deg);
      transform-origin: top left;
      z-index: 10;
    }
  }
  &.right{
    &:before {
      position: absolute;
      bottom: -6rem;
      right: 50%;
      content: '';
      display: block;
      width: 50%;
      height: 6rem;
      background: inherit;
      transform: skewY(5deg);
      transform-origin: top right;
      z-index: 10;
    }
    &:after {
      position: absolute;
      bottom: -6rem;
      left: 50%;
      content: '';
      display: block;
      width: 50%;
      height: 6rem;
      background: inherit;
      transform: skewY(-5deg);
      transform-origin: top left;
      z-index: 10;
    }
  }
}

.about {
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include breakpoint(medium) {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }
  background: $black;
  color: $white;
}

.service {
  padding-top: 5rem;
  padding-bottom: 0rem;
  @include breakpoint(medium) {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
  background: $blue;
  color: $white;
  h2 {
    color: $white;
  }
}

.support {
  padding-top: 10rem;
  padding-bottom: 4rem;
  @include breakpoint(medium) {
    padding-top: 15rem;
    padding-bottom: 10rem;
  }
  background: $black;
  color: $white;
}

.bullets {
  background: $blue;
  color: $white;
  padding-bottom: 2rem;

  p {
    line-height: 1.2;
  }
  h2 {
    color: $white;
    text-transform: uppercase;
  }
  img {
    width: 8rem;
    margin-bottom: 1rem;
  }
  .cell {
    padding: 2rem;
  }
}

.demo-request {
  padding-top: 10rem;
  padding-bottom: 4rem;
  @include breakpoint(medium) {
    padding-top: 14rem;
    padding-bottom: 8rem;
  }
  background: $white;
  .button {
    margin-top: 2rem
  }
}

.demo-request-form {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include breakpoint(medium) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  label {
    color: $white;
  }
}

.message {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include breakpoint(medium) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .header {
    width: 100%;
    @include breakpoint(medium) {
      width: 90%;
    }
    margin: 0 auto 4rem;
  }
}

.header {
  text-align: center;
  color: $blue;
  margin-bottom: 2rem;
  line-height: 1.1;
}



footer {
  text-align: center;
  position: relative;
  background: $black;
  padding-top: 10rem;
  padding-bottom: 5rem;
  color: $blue;
  p {
    font-size: 1.125rem;
  }
  .links {
    display: flex;
    flex-direction: column;
  }
  a {
    font-size: 1.5rem;
    display: block;
    margin: 0 auto;
    color: $blue;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 2px;
      background: mix($blue,$white,20%);
      transform: scale(0);
      transition: all 0.5s ease;
    }
    &:hover,&:active,&:focus {
      color: mix($blue,$white,20%);
      &:before {
        transform: scale(1);
      }
    }
  }
  h2 {
    margin-bottom: 3rem;
    line-height: 1.2;
    text-transform: uppercase
  }
  .legal {
    margin-top: 4rem;
  }
}

.mouse-mark {
  .icon {
    margin: 2rem auto 5rem;
    display: block;
    height: 2rem;
    width: 2rem;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(-45deg);
    animation-name: bounceIn;
    animation-duration: 5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

button {
  outline: 0;
  &:focus {
    outline:0;
  }
}

.button {
  border-radius: 500px;
  padding: 0.85em 2em;
  position: relative;
  transition: all 0.2s ease;
  &.large {
    font-size: 1rem;
    @include breakpoint(medium) {
      font-size: 1.125rem;
    }
  }
  &.big {
    font-size: 1rem;
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }
  &.blue {
    background: $blue;
    &:hover {
      background: mix($black,$blue,10%);
    }
  }
  &.white.hollow {
    border-color: $white;
    color: $white;
    &:hover {
      background: rgba($white,0.2);
    }
  }
  &.grey.hollow {
    border-color: mix($black,$white,50%);
    color: mix($black,$white,50%);
    &:hover {
      background: rgba(mix($black,$white,50%),0.2);
    }
  }
  &.animated {
    &:before,&:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom:0;
      left:0;
      right:0;
      border-radius: 500px;
      border: 1px solid $blue;
      animation-name: pingwave;
      animation-duration: 4s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
    }
    &:after {
      animation-name: pingwave1;
    }
  }
}

.icon.back {
  display: inline-block;
  line-height: 1;
  width: 2em;
  height: 1em;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5em;
    display: block;
    height: 1em;
    width: 1em;
    border-top: 1px solid $white;
    border-left: 1px solid $white;
    transform: translateY(-50%) rotate(-45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5em;
    display: block;
    height: 1px;
    width: 1.5em;
    background:  $white;
    transform: translateY(-50%);
  }
}

@keyframes pingwave{
  0%{
    opacity:1;
    width:100%;
    left:0;
    top:0%;
    bottom: 0%;
  }
  10%{
    opacity:0;
    left: -15%;
    width:130%;
    top:-50%;
    bottom: -50%;
  }
  100%{
    opacity:0;
    left: -15%;
    width:130%;
    top:-50%;
    bottom: -50%;
  }
}

@keyframes pingwave1{
  0%{
    opacity:1;
    width:100%;
    left:0;
    top:0%;
    bottom: 0%;
  }
  10%{
    opacity:1;
    width:100%;
    left:0;
    top:0%;
    bottom: 0%;
  }
  20%{
    opacity:0;
    left: -15%;
    width:130%;
    top:-50%;
    bottom: -50%;
  }
  100%{
    opacity:0;
    left: -15%;
    width:130%;
    top:-50%;
    bottom: -50%;
  }
}

@keyframes bounceIn{
  0%{
    transform: translateY(0) rotate(-45deg);
  }
  10% {
    transform: translateY(50%) rotate(-45deg);
  }
  17.5%{
    transform: translateY(0) rotate(-45deg);
  }
  22.5% {
    transform: translateY(25%) rotate(-45deg);
  }
  27%{
    transform: translateY(0) rotate(-45deg);
  }
  31%{
    transform: translateY(12%) rotate(-45deg);
  }
  34%{
    transform: translateY(0) rotate(-45deg);
  }
  100%{
    transform: translateY(0) rotate(-45deg);
  }
}

form {
  .actions {
    @include breakpoint(medium){
      display: flex;
      justify-content: space-between;
    }

    margin-top: 2rem;
    text-align: center;

  }
  .field {
    margin-bottom: 1rem;
    &.required {
      label {
        position: relative;
        &:after {
          content: '\2217';
          color: red;
          position: absolute;
          top: -0.75em;
          font-size: 1.5em;
        }
      }
    }
    input.error {
      background: mix(red,$white,10%);
    }
    input.valid {
      background: mix(green,$white,10%);
    }
  }

  .form-error {
    display: block;
    color: red;
  }
}